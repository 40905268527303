import React from "react";
import "../App.css";

function Home({ path, params }) {
  return (
    <div className="App">
      <div style={{ paddingTop: 50 }}>
        <img style={{ width: 150, height: 150 }} alt="Bestcount logo" src="https://img.bestcount.ph/assets/bestcount-subscriber-app/logo.png" />
      </div>
      <div style={{ fontSize: 22, fontWeight: "bold" }}>Welcome to Bestcount</div>
      {/* <div style={{ fontSize: 18 }}>{path[1]}</div> */}
    </div>
  );
}

export default Home;
