import axios from "axios";

const BASE_URL = "https://api.bestcount.ph/v1";
// const BASE_URL = "http://localhost:3502/v1";
const GUEST_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbkNsYWltIjoiMDdmODNmZjgtNDJkMy00ZTBiLTk0NDUtZjUzMTI3OWNiOWZmIiwiaWF0IjoxNjUwMjcxMDU1fQ.2dZZeq_aG6FJ88IctTojENdrX5UmnZasgZxj9F4qdyE";

const axiosPublic = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${GUEST_TOKEN}`,
  },
  withCredentials: true,
  timeout: 10000,
});

export default axiosPublic;
