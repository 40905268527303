import moment from "moment";

const _f = {};

_f.isInt = (s) => {
  var rgx = /^[0-9]*$/;
  //   console.log(rgx.test(s));
  return rgx.test(s);
};

_f.isDecimal = (s) => {
  let rgx = /^[0-9]*\.?[0-9]*$/;
  //   console.log(rgx.test(s));
  return rgx.test(s);
};

_f.isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};

_f.isValidDate = (date) => {
  return moment(date).isValid();
};

_f.isValidPhone = (num) => {
  let mobilePhone = num.replace("+", "");
  if (mobilePhone.length === 11 && mobilePhone[0] === "0") {
    mobilePhone = "63" + mobilePhone.substring(1);
  }
  return mobilePhone.length === 12 && mobilePhone[0] === "6" && mobilePhone[1] === "3";
};

_f.toPhone = (num) => {
  let mobilePhone = num.replace("+", "");
  if (mobilePhone.length === 11 && mobilePhone[0] === "0") {
    mobilePhone = "63" + mobilePhone.substring(1);
  }
  return mobilePhone;
};

export default _f;
