/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import axiosPublic, { axiosPrivate } from "../components/axios";
import _f from "../components/functions";
import Swal from "sweetalert2";

function Register({ params, refId }) {
  const initStart = useRef(false);
  const [initDone, setInitDone] = useState(false);
  const [referrer, setReferrer] = useState({});
  const [input, setInput] = useState({});

  useEffect(() => {
    if (refId && !initStart.current) {
      initStart.current = true;
      __getReferrer(refId);
    }
  }, []);

  const __getReferrer = async (refId) => {
    const res = await axiosPrivate.get(`/subscriber/${refId}`);
    if (res.data) {
      setReferrer(res.data);
    }
    setInitDone(true);
  };

  const __changeInput = (name, value) => {
    // console.log(name, value);
    if (name === "mobilePhone" && !_f.isInt(value)) return;
    setInput({ ...input, [name]: value });
  };

  const __signUp = async () => {
    const payload = JSON.parse(JSON.stringify(input));

    if (!payload.mobilePhone) {
      __alert("error", "Mobile number is required");
      return;
    } else {
      if (!_f.isValidPhone(payload.mobilePhone)) {
        __alert("error", "Invalid mobile number");
        return;
      } else {
        payload.mobilePhone = _f.toPhone(payload.mobilePhone);
      }
    }
    if (!payload.email) {
      __alert("error", "Email is required");
      return;
    } else {
      if (!_f.isValidEmail(payload.email)) {
        __alert("error", "Invalid email address");
        return;
      }
    }
    if (!payload.firstName) {
      __alert("error", "First name is required");
      return;
    }
    if (!payload.lastName) {
      __alert("error", "Last name is required");
      return;
    }
    if (!payload.password) {
      __alert("error", "Password is required");
      return;
    }
    if (payload.password !== payload.password2) {
      __alert("error", "Passwords does not match");
      return;
    }
    if (!payload.agree) {
      __alert("error", "You need to agree to our TOS");
      return;
    }
    delete payload.password2;
    payload.referrerId = referrer.id;

    try {
      const response = await axiosPublic.post("/register/subscriber", payload, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      if (response.data) {
        Swal.fire({
          icon: "success",
          title: "Registration successful!",
          text: "Download the app now then login to start enjoying discounts you deserve!",
          showConfirmButton: false,
          timer: 1500,
        });

        setTimeout(() => window.location.replace("https://bestcount.ph"), 1500);
      }
    } catch (err) {
      if (!err?.response.status) {
        console.log("No Server Response", err);
        __alert("info", "Check your internet connection", "No Server Response");
      } else if (err?.response.data) {
        console.log(err?.response.data.msg);
        __alert("error", `${err.response.data.msg}`, "Registration Error");
      } else {
        __alert("error", `Contact our support@bestcount.com.ph`, "Something went wrong");
      }
    }
  };

  const __alert = (type = "info", msg, title = null) => {
    Swal.fire({
      title: title,
      text: msg,
      icon: type,
      confirmButtonText: "Ok",
      confirmButtonColor: "rgb(0,78,168)",
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", backgroundImage: `url("https://img.bestcount.ph/assets/bestcount-subscriber-app/appbackground.jpg")`, paddingBottom: 20, minHeight: "100vh" }}>
      {refId && initDone && (
        <>
          {referrer.id ? (
            <div style={{ padding: 5, backgroundColor: "rgba(255,255,255,0.8)", borderRadius: 10, marginTop: 10, fontSize: 12 }}>
              🎉 {referrer.firstName} {referrer.lastName} has brought you here 🎉
            </div>
          ) : (
            <div style={{ padding: 5, backgroundColor: "rgba(255,255,255,0.8)", borderRadius: 10, marginTop: 10, fontSize: 12 }}>❌ Invalid Referrer</div>
          )}
        </>
      )}
      <div style={{ paddingTop: 40 }}>
        <img style={{ width: 150, height: 150 }} alt="Bestcount logo" src="https://img.bestcount.ph/assets/bestcount-subscriber-app/logo.png" />
      </div>
      <div style={{ fontSize: 22, fontWeight: "bold" }}>Welcome to Bestcount</div>

      <div style={{ fontSize: 16 }}>Fill in your details to continue!</div>
      <div style={{ maxWidth: 300, width: "100%", alignItems: "flex-start", marginLeft: "auto", marginRight: "auto" }}>
        <div style={{ marginTop: 20 }}>
          <div style={styles.formGroupHeader}>Contact Information</div>
          <div style={styles.inputLabel}>Mobile Number</div>
          <input style={styles.inputField} value={input.mobilePhone ?? ""} onChange={(e) => __changeInput("mobilePhone", e.target.value)} />
          <div style={{ color: "rgba(176, 34, 34, 1)", fontSize: 10 }}>*An OTP will be sent on your first login</div>
          <div style={styles.inputLabel}>Email</div>
          <input style={styles.inputField} value={input.email ?? ""} onChange={(e) => __changeInput("email", e.target.value)} />
        </div>
        <div style={{ marginTop: 20 }}>
          <div style={styles.formGroupHeader}>Personal Details</div>
          <div style={styles.inputLabel}>First Name</div>
          <input style={styles.inputField} value={input.firstName ?? ""} onChange={(e) => __changeInput("firstName", e.target.value)} />
          <div style={styles.inputLabel}>Middle Name</div>
          <input style={styles.inputField} placeholder="Optional" value={input.middleName ?? ""} onChange={(e) => __changeInput("middleName", e.target.value)} />
          <div style={styles.inputLabel}>Last Name</div>
          <input style={styles.inputField} value={input.lastName ?? ""} onChange={(e) => __changeInput("lastName", e.target.value)} />
        </div>
        <div style={{ marginTop: 20 }}>
          <div style={styles.formGroupHeader}>Credentials</div>
          <div style={styles.inputLabel}>Password</div>
          <input type="password" style={styles.inputField} value={input.password ?? ""} onChange={(e) => __changeInput("password", e.target.value)} />
          <div style={styles.inputLabel}>Confirm</div>
          <input type="password" style={styles.inputField} value={input.password2 ?? ""} onChange={(e) => __changeInput("password2", e.target.value)} />
          <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
            <input type="checkbox" style={{ height: 20, width: 20, marginRight: 5 }} checked={input.agree ?? false} onChange={(e) => __changeInput("agree", !input.agree)} />
            <span style={{ marginTop: 2 }}>I agree to Terms and Conditions</span>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 20, justifyContent: "center" }}>
            <button style={{ padding: 10, fontSize: 18, borderRadius: 10, backgroundColor: "rgb(0,78,168)", color: "white" }} onClick={__signUp}>
              Sign-up
            </button>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 20, justifyContent: "center" }}>
            <div style={{ fontSize: 18, color: "white" }} onClick={() => window.location.replace("https://bestcount.ph")}>
              I already have an account
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  formGroupHeader: {
    fontSize: 18,
    fontWeight: "bold",
  },
  inputLabel: {
    fontSize: 16,
    marginTop: 5,
  },
  inputField: {
    width: "96%",
    padding: 5,
    fontSize: 18,
    borderRadius: 5,
    borderWidth: 0,
  },
};

export default Register;
