import Register from "./pages/Register";
import Home from "./pages/Home";

function App() {
  const url = new URL(window.href);
  const params = new URLSearchParams(url.search);
  const path = url.pathname.split("/");

  if (path[1]) {
    if (path[1] === "register") return <Register params={params} />;
    //if referral link
    const referrerUid = path[1].split("-");
    if (referrerUid.length === 3 && referrerUid[0] === "S" && !isNaN(+referrerUid[2])) {
      return <Register params={params} refId={+referrerUid[2]} />;
    }
  }
  return <Home path={path} params={params} />;
}

export default App;
